<template>
<div>
  <div style="margin: 1rem 0">
    <div class="gift__wrapper">
      <div class="gift-info">
        <CartImage :item="gift" />

        <div class="description__wrapper">
          <Brand
            v-if="
              gift.brand && gift.brand.name && gift.type != ITEM_TYPES.ECI_PRIME
            "
            :brand="gift.brand"
            :className="'cart'"
          />

          <span class="title">{{ gift.title }}</span>
        </div>
      </div>
      <div class="gift-badge">
        <i class="gift_icon"></i>
        {{ $t('global.free') }}
      </div>
    </div>
    <div v-if="gift.description" class="textError">
      <p>* {{ $t(gift.description) }}</p>
    </div>
  </div>
  </div>
</template>

<script>
import CartImage from './UI/CartImage.vue';
import CartPrice from 'Components/Checkout/CartPrice.vue';
import CartVariants from './UI/CartVariants.vue';
import CartActionsMiniBasket from 'Components/Checkout/CartActionsMiniBasket.vue';

import ITEM_TYPES from 'CommonUtils/itemTypes.js';

import Brand from 'CommonComponents/Product/Brand.vue';

export default {
  name: 'CartGift',
  props: {
    gift: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ITEM_TYPES
    }
  },
  components: {
    CartImage,
    CartPrice,
    CartVariants,
    CartActionsMiniBasket,
    Brand
  }
};
</script>
