<template>
  <picture class="image__wrapper">
    <a :href="goToPDP(item)">
      <img
        :class="imgIsLoaded ? 'show' : ''"
        :title="item.title"
        :image="item.image.url"
        :alt="item.title"
        :src="item.image && item.image.url ? item.image.url : item.default_image"
        :data-src="item.image"
        @error="imgPlaceholder"
        @load="imgLoadedMethod"
      />
    </a>
  </picture>
</template>

<script>

import PDPRedirect from 'CommonUtils/pdpRedirect.js';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      goToPDP: PDPRedirect.goToPDP,
      imgIsLoaded: false,
      errorImg:
        'https://www.elcorteingles.es/sgfm/SGFM/contents/images/common/no-image.png',
    };
  },
  methods: {
    imgLoadedMethod() {
      this.imgIsLoaded = true;
    },
    imgPlaceholder(e) {
      e.target.src = this.errorImg;
    }
  },
};
</script>

<style lang="less" scoped>
//TODO: Hay que mirar el tipo de animación
.image__wrapper {
  img {
    opacity: 0;
    transition: 1s;
  }

  img.show {
    opacity: 1;
  }
}
</style>