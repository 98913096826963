<template>
  <div class="cart__container__desktop">
    <TitleCart :label="$t('cart.shopping_cart')" />

    <div class="empty-cart" v-if="!fullItemsCart.length">
      {{$t('cart.empty_cart_description')}}
    </div>

    <div v-else class="wrapper__full__products">
      
      <div class="wrapper__cart__list">
        <CartList :items="fullItemsCart" />
      </div>

      <CartDetails
        :summary="fullSummaryData"
        :fullDiscountsData="fullDiscountsData"
      />
    </div>
  </div>
</template>

<script>
import TitleCart from './UI/TitleCart.vue';
import CartDetails from './UI/CartDetails.vue';
import CartList from './CartList.vue';

import { mapGetters } from 'vuex';

export default {
  components: {
    CartList,
    TitleCart,
    CartDetails
  },
  computed: {
    fullItemsCart() {
      return this.cartData.items;
    },
    fullSummaryData() {
      return this.cartData.summary;
    },
    fullDiscountsData() {
      return this.cartData.order_discounts;
    },
    ...mapGetters('cart', {
      cartData: 'getFullCartData'
    })
  }
};
</script>

<style></style>
