<template>
  <button
    data-testid="SearchBarLink"
    :class="'search-link ' + (searchTermText?.length ? '__has-term' : '')"
    @click="showSearch">
    <CloseIcon 
      v-if="searchTermText?.length" 
      class="search-link-clear__icon" 
      @click.prevent="showClearSearch" />
    <MagnifyingGlassIcon class="search-link__icon" />
    <p>{{ searchTermText?.length && !isTextBooks ? searchTermText : $t('search.what_are_you_looking_for') }}</p>
  </button>
</template>

<script>
  import { mapGetter } from 'CommonUtils/store/state.js';
  import { MagnifyingGlassIcon, CloseIcon } from 'CommonComponents/UI/Atoms/Icons';
  export default {
    name: 'SearchBarLink',
    components: { 
      CloseIcon,
      MagnifyingGlassIcon 
    },
    props: {
      isTextBooks: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['showSearch', 'showClearSearch'],
    setup(props, { emit }) {
      const searchTermText = mapGetter('search-filters/getSearchTerm');
      const showSearch = () => {
        emit('showSearch');
        focusTemporalInput();
      };
      const focusTemporalInput = () => {
        // HACK to show the keyboard on iOS, as it doesn't allow to focus an element outside the click event.
        // We are creating an input that gains focus and shows keyboard
        // and when this temporal input loses its focus, we remove the child
        const temporalInput = document.createElement('input');
        temporalInput.style.cssText = `
          position: absolute;
          top: 100px;
          left: 20px;
          height: 0;
          width: 0;
          opacity: 0;`
        document.body.appendChild(temporalInput);
        temporalInput.focus();
        temporalInput.addEventListener('blur', () => {
          document.body.removeChild(temporalInput);
        })
      }
      const showClearSearch = () => {
        emit('showClearSearch');
      }
      return {
        showSearch,
        showClearSearch,
        searchTermText,
      };
    },
  };
</script>
