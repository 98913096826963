<template>
  <div class="row-details">
    <p :class="isTotalPrice ? 'total' : 'text-detail'">{{ label }}</p>
    <p
      v-html="formatPrice(info, this.type)"
      :class="isTotalPrice ? 'total' : 'text-detail'"
    />
  </div>
</template>

<script>
import PriceFilters from 'CommonUtils/priceFilters.js';

export default {
  props: {
    label: {
      type: String,
      required: false,
    },
    info: {
      type: [String, Number],
      required: false,
    },
    isTotalPrice: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'COMPLEX',
    },
  },
  data() {
    return {
      formatPrice: PriceFilters.formatPrice,
    };
  },
};
</script>

