<template>
  <h3 class="title-cart" v-if="mostrar">{{label}}</h3>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    mostrar: {
      type: Boolean,
      default: true
    }
  },
};
</script>