import { computed } from 'vue';
import { mapGetter } from 'CommonUtils/store/state.js';

const login = () => {
  const isLogged = mapGetter('cart/isLogged');
  const user = mapGetter('cart/getUser');
  const siteBar = mapGetter('page/getSiteBar');
  const getWishlist = mapGetter('wishlist/getWishlist');

  const loginUrl = computed(()=>{
    const url = siteBar.value && siteBar.value.links && siteBar.value.links.login;
    return url || '';
  });

  const oneClicLoginUrl = computed(()=>{
    const url = siteBar.value && siteBar.value.links && siteBar.value.links.one_clic;
    return url || '';
  });

  const brandFidelizationLoginUrl = computed(()=>{
    const url = siteBar.value && siteBar.value.links && siteBar.value.links.brandFidelizationLoginLink;
    return url || '';
  });

  const logoutUrl = computed(()=>{
    const url = siteBar.value && siteBar.value.links && siteBar.value.links.logout;
    return url || '';
  });

  const myAccountUrl = computed(()=>{
    const url = siteBar.value && siteBar.value.links && siteBar.value.links.my_account;
    return url || '';
  });

  const myOrdersUrl = computed(()=>{
    const url = siteBar.value && siteBar.value.links && siteBar.value.links.my_orders;
    return url || '';
  });

  const wishlistUrl = computed(()=> {
    let url = '';
    if(!isLogged.value) url = siteBar?.value?.links?.login;
    if(Object.keys(getWishlist.value)[0]) url =  siteBar?.value?.links?.wishlist + '/' + Object.keys(getWishlist.value)[0] ;
    return url;
  });

  const oneClicLinks = computed(()=> {
    const urls = siteBar.value && siteBar.value.one_clic_links;
    return urls || null;
  });


  return { 
    brandFidelizationLoginUrl,
    isLogged, 
    loginUrl,
    logoutUrl,
    myAccountUrl,
    myOrdersUrl,
    oneClicLinks,
    oneClicLoginUrl,    
    siteBar,
    user,     
    wishlistUrl
  }
};

export default login;
