<template>
  <main
    id="mainElement"
    class="main"
    :class="mainClass">
    <div
      class="_sticky-wrapper"
      :class="!isApp && !bandPlacement && 'band--empty'">
      <Transition name="main">
        <Sheet
          v-if="bandPlacement && bandPlacement?.html && !isBandHidden"
          :placement="bandPlacement"
          :container-class="null" />
      </Transition>
      <SearchApp
        v-if="shouldShowSearchApp"
        @scrolling="handleScroll" />
    </div>
    <div v-if="hasApiTemplateHeader">
      [HEAD]
    </div>

    <HeaderWeb
      :show-header="shouldShowHeader"
      :is-home="isHome"      
      @handle-fold="fold"
      @show-search="handlerShowSearch" />

    <div
      v-show="!showSearchContainer"
      class="main__content"
      :style="mainContentStyle">
      <slot />
    </div>
    <AccesibilityButton />
    <template v-if="shouldShowFooter">
      <Teleport
        v-if="hasMountedParallaxFooterContainer"
        to="#parallax_footer">
        <FooterWeb />
      </Teleport>
      <FooterWeb v-else />
    </template>
    <ToastMessage />
    <OIDCSession
      v-if="isProd"
      :is-server="isServer" />
    <InitialAlerts @cookiesAccepted="setBandVisibility" />
    <ChatWidget
      v-if="showChatWidget"
      :is-experts-chat="isExpertsChat"
      :is-real-experts-time="isRealExpertsTime"
      :open-chat-automatically="openChatAutomatically"
      :is-app-webview="isUrlForChatbotWebView" />
    <SearchContainer
      v-if="showSearchContainer"
      :isbnList="isbnList"
      :show-search-input="true"
      :save-active-url="true"
      :has-band="!isBandHidden"
      :is-home="isHome"
      :is-text-books-search="isTextBooksCategory"
      @close-search="handlerCloseSearch"
      @clean-isbn-list="resetIsbn" />
    <CustomerNotification v-if="isCustomerNotificationOpen" />
    <SizeGuideModal v-if="isSizeGuideModalOpen" />
    <div id="Layout-modal-customerNotification" />
    <div id="Layout-modal-fidelization" />
    <div id="Layout-modal-fidelization-external-link" />
    <div id="Layout-modal-financeCalculator" />
    <div id="Layout-modal-extraInfo" />
    <div id="Layout-modal-extraInfo-z-index-back-header" />
    <div id="Layout-modal-iframe" />
    <div id="Layout-modal-imageViewer" />
    <div id="Layout-modal-modalCookies" />
    <div id="Layout-modal-modalFilterFacet" />
    <div id="Layout-modal-modalFilterFacetTag" />
    <div id="Layout-modal-modalFilter" />
    <div id="Layout-modal-modalFilterSearch" />
    <div id="Layout-modal-modalMarket" />
    <div id="Layout-modal-modalSearch" />
    <div id="Layout-modal-modalSize" />
    <div id="Layout-modal-modalUser" />
    <div id="Layout-modal-modalWishlist" />
    <div id="Layout-modal-newsletter-designers" />
    <div id="Layout-modal-newsletter-feedback" />
    <div id="Layout-modal-productCustomization" />
    <div id="Layout-modal-searchSpeech" />
    <div id="Layout-modal-modalCategories" />
    <div id="Layout-modal-expert" />
    <Chat
      v-if="viewChat"
      :config-chat="configChat"
      :type="chatType"
      @close-chat="closeChat"
      @reloadPDPRecommendedByExpert="reloadPDPRecommendedByExpert" />
  </main>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import HeaderWeb from './Header/Header.vue';
  import FooterWeb from './Footer/Footer.vue';
  import OIDCSession from 'CommonComponents/OIDCSession/OIDCSession.vue';
  import Sheet from 'CommonComponents/Sheets/Render.vue';
  import NavigatorDetect from 'CommonMixins/navigatorDetect.js';
  import UtilsAnalytics from 'CommonUtils/analytics.js';
  import UtilsPlacements from 'CommonUtils/placements.js';
  import UtilsCookies from 'CommonUtils/cookies.js';
  import UtilExposer from 'CommonUtils/exposer.js';
  import AccesibilityButton from 'Components/Vendors/EqualWeb/Accesibility.vue';
  import ToastMessage from 'CommonComponents/ToastMessage/ToastMessage.vue';
  import ICON_NAMES from 'CommonUtils/iconNames';

  export default {
    name: 'Layout',
    components: {
      HeaderWeb,
      FooterWeb,
      Chat: defineAsyncComponent(() => import('Components/Chat/Chat.vue')),
      ChatWidget: defineAsyncComponent(() => import('IAChatComponents/ChatWidget.vue')),
      ToastMessage,
      OIDCSession,
      InitialAlerts: defineAsyncComponent(() => import('./InitialAlerts.vue')),
      Sheet,
      SearchApp: defineAsyncComponent(() => import('CommonComponents/Search/App/SearchApp.vue')),
      CustomerNotification: defineAsyncComponent(() => import('CommonComponents/Product/CustomerNotification.vue')),
      AccesibilityButton,
      SizeGuideModal: defineAsyncComponent(() => import('CommonComponents/SizeGuide/SizeGuideModal.vue')),
      SearchContainer: defineAsyncComponent(() => import('Components/Search/SearchContainer.vue')),
    },
    mixins: [NavigatorDetect],
    provide() {
      return {
        openChat: this.openChat,
        closeChat: this.closeChat,
      };
    },
    props: {
      isHome: { type: Boolean, default: false },
      leanLayout: { type: Boolean, default: false },
      hasContextMenu: { type: Boolean, default: false },
      bandPlacement: { type: Object, default: null },
      showStickyInApp: { type: Boolean, default: false },
      hasToShowHeader: { type: Boolean, default: true },
      hasToShowFooter: { type: Boolean, default: true },
      isbnList: { type: Array, default: () => [] },
      setNewSearch: { type: Boolean, default: false },
      isTextBooksCategory: { type: Boolean, default: false },
      hasApiTemplateHeader: { type: Boolean, default: false },
    },
    emits: ['scrolling', 'reloadPDPRecommendedByExpert', 'resetIsbn'],
    data() {
      return {
        showBreadcrumb: false,
        mainContentStyle: '',
        configChat: {},
        viewChat: false,
        chatType: '',
        hasCalledCartService: false,
        isMounted: false,
        showContent: false,
        segments: '',
        mustShowBand: false,
        showSearchContainer: false,
      };
    },
    computed: {
      ...mapGetters('page', {
        sitebar: 'getSiteBar',
        isApp: 'isApp',
        isNewApp: 'isNewApp',
        isEciAppFocus: 'isEciAppFocus',
        isModal: 'isModal',
        isServer: 'isServer',
        isProd: 'isProd',
        breadcrumbs: 'getBreadcrumbs',
        isPdp: 'getIsPdp',
        hasBrandCustomization: 'hasBrandCustomization',
        brandCustomization: 'getBrandCustomization',
        isAMV: 'getIsAMV',
        hasProductListBar: 'hasProductListBar',
        hasParallaxSheet: 'hasParallaxSheet',
        hasMountedParallaxFooterContainer: 'hasMountedParallaxFooterContainer',
        layoutOptions: 'getLayoutOptions',
        getChatbotUsers: 'getChatbotUsers',
        getBaseUrl: 'getBaseUrl',
        getChatbotSegments: 'getChatbotSegments',
        getIsFashion: 'getIsFashion',
        internals: 'getInternals',
      }),
      ...mapGetters('cart', {
        getFlatRate: 'getShippingPrimeActivated',
        getCartLineItems: 'getCartLineItems',
        isCartLoading: 'getLoadingStatus',
        user: 'getUser',
        loyaltyPoints: 'getLoyaltyData',
        isLogged: 'isLogged',
        redirectUrlBasket: 'getRedirectUrlBasket',
      }),
      ...mapGetters('uiState', {
        isCustomerNotificationOpen: 'getStatusCustomerNotification',
        isSizeGuideModalOpen: 'getStatusModalSizeGuide',
        isCartOpen: 'getStatusModalMarket',
      }),
      ...mapGetters('layout', {
        getPageScrollPosition: 'getPageScrollPosition',
      }),
      isCustomBrandEnabled() {
        if (this.showSearchContainer) return false;
        return this.hasBrandCustomization && this.brandCustomization.enabled;
      },
      currentBrand() {
        if (!this.hasBrandCustomization || !this.brandCustomization || !this.brandCustomization.className) return '';
        return this.brandCustomization.className;
      },
      hasCustomBrandLogo() {
        return this.isCustomBrandEnabled && this.brandCustomization.customizations.logo === true;
      },
      mainClass() {
        return {
          _has_band: !this.isBandHidden,
          _has_context_menu: this.hasContextMenu,
          _has_app_search: this.isApp && !this.isPdp && !this.isNewApp,
          _has_product_list_bar: this.hasProductListBar,
          _has_breadcrumbs: this.breadcrumbs?.length > 1,
          _is_modal: this.isModal,
          _is_app: this.isApp,
          _is_amv: this.isAMV,
          _is_plp: !this.isPdp,
          _is_pdp: this.isPdp,
          _show_sticky_in_app: this.showStickyInApp,
          [this.brandCustomization && this.brandCustomization.className]: this.isCustomBrandEnabled,
          custom_logo: this.hasCustomBrandLogo,
        };
      },
      isBandHidden() {
        return this.isMounted ? UtilsPlacements.isHiddenByCDPSegments(this.bandPlacement) && !this.mustShowBand : true;
      },
      shouldShowHeader() {
        if (!this.hasToShowHeader) return false;

        return !this.isApp && !this.isModal && !this.isAMV;
      },
      shouldShowFooter() {
        if (!this.hasToShowFooter) return false;
        if (this.isMounted && this.isUrlForChatbot) return false;
        if (this.showSearchContainer) return false;
        if (this.isPdp && !this.isDesktopDeviceByHeaderAndResolution) return false;

        return !this.isApp && !this.isModal && !this.isAMV;
      },
      shouldShowSearchApp() {
        return ((this.isApp && !this.isNewApp) || this.isEciAppFocus) && !this.isPdp && !this.isModal;
      },
      isUrlForChatbot() {
        const currentURL = window.location.href;
        return currentURL.includes('/nuevo-buscador-eci');
      },
      isUrlForChatbotWebView() {
        const currentURL = window.location.href;
        return currentURL.includes('/asistente-ia-webview/');
      },
      isExpertsChat() {
        if (this.layoutOptions?.show_chat_gpt_experts) return true;
        else return false;
      },
      isRealExpertsTime(){
        if (this.layoutOptions?.experts_time) return true;
        else return false;
      },
      openChatAutomatically() {
        if (this.isUrlForChatbotWebView) {
          return true;
        }
        return false;
      },
      loginUrl() {
        const url = this.sitebar && this.sitebar.links && this.sitebar.links.login;
        return url || '';
      },
      userHasChabotSegment() {
        if (!this.getChatbotSegments.length) return true;
        if (!this.segments) return false;

        const arrSegments = this.segments.split(',');
        if (!arrSegments || arrSegments.length === 0) return false;
        return arrSegments.some((elemento) => this.getChatbotSegments.includes(elemento));
      },
      showChatWidget() {
        if (!this.isMounted) return false;
        if (this.isModal && this.isApp && this.isUrlForChatbotWebView) return true;
        if (this.isModal) return false;
        if (this.isPdp && this.isExpertsChat) return true;
        if (this.isUrlForChatbot) return true;
        if (!this.layoutOptions?.show_chat_gpt) return false;
        if (this.showSearchContainer) return false;
        if (this.userHasChabotSegment) return true;
        return false;
      },
      shouldRedirectToBasketMobile() {
        return this.isMobileDevice;
      },
    },
    watch: {
      isCartLoading(newVal, oldVal) {
        if (newVal === false && oldVal === true && !this.hasCalledCartService) {
          this.hasCalledCartService = true;
          const cookiesPolicy = UtilsCookies.getCookieValue('cookiesPolicy');
          const zipCode = UtilsCookies.getCookieValue('zip');
          const flatRate = this.getFlatRate;
          UtilsAnalytics.sendUserPush({
            ...this.user,
            cookiesPolicy,
            zipCode,
            flatRate,
            loyaltyPoints: this.loyaltyPoints,
          });

          this.showContent = true;
        }
      },
      async user(newVal) {
        if (newVal && this.isUrlForChatbot) {
          if (this.getChatbotUsers.length === 0) return;
          const email = this.user.email;
          if (!email) {
            window.location.href = this.getBaseUrl + '/usuario-no-autorizado/';
            return;
          }
          const hashedEmail = await this.encryptSHA256Hash(email);
          if (!this.getChatbotUsers.includes(hashedEmail))
            window.location.href = this.getBaseUrl + '/usuario-no-autorizado/';
        }
      },
      isbnList: {
        handler() {
          this.showSearchContainer = this.isbnList.length ? !this.showSearchContainer : this.showSearchContainer;
        },
      },
    },
    mounted() {
      if (!this.isServer) {
        document.addEventListener('AdobeSDKLoaded', this.setSegmentsFromLocalStorage);
        this.setSessionId(UtilsCookies.getCookie('session_id') || '');
      }
      if (!UtilsCookies.getCookie('msh.sid') && this.isUrlForChatbot) window.location.href = this.loginUrl;

      UtilExposer(
        ['placements', 'compositions', 'cdp'],
        'removeHiddenCDPSegments',
        UtilsPlacements.removeHiddenByCDPSegmentsAutofind,
      );
      UtilExposer(['cart', 'content'], 'getLineItems', this.getCartLineItemsFromStore);
      UtilExposer(['geci'], 'createCookie', this.createCookieExpertVendor);
      UtilExposer(['cart'], 'toggleCart', this.toggleCart);
      this.isMounted = true;
    },
    unmounted() {
      document.removeEventListener('AdobeSDKLoaded', this.setSegmentsFromLocalStorage);
    },
    methods: {
      ...mapActions('page', ['setSessionId']),
      ...mapActions('uiState', ['showModal']),
      ...mapActions('layout', ['setPageScrollPosition']),
      setBandVisibility(visibility) {
        this.mustShowBand = visibility;
      },
      setSegmentsFromLocalStorage() {
        const adobeSegments = localStorage.getItem('kxelcorteinglessa_segs');
        this.segments = adobeSegments;
        UtilsCookies.setCookie('kxelcorteinglessa_segs', adobeSegments);
      },
      async encryptSHA256Hash(input) {
        const textAsBuffer = new TextEncoder().encode(input);
        const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hash = hashArray.map((item) => item.toString(16).padStart(2, '0')).join('');
        return hash;
      },
      fold(folded, navHeight) {
        if (folded && navHeight) {
          this.mainContentStyle = `margin-top: ${navHeight}px;`;
        } else {
          this.mainContentStyle = '';
        }
      },
      closeChat() {
        this.viewChat = false;
      },
      openChat(configChat, chatType) {
        this.viewChat = false;
        this.$nextTick(() => {
          this.configChat = configChat;
          this.viewChat = true;
          this.chatType = chatType;
        });
      },
      handleScroll(e) {
        this.$emit('scrolling', e);
      },
      getCartLineItemsFromStore() {
        return this.getCartLineItems;
      },
      createCookieExpertVendor(nameCookie, cookieVal, days = 15) {
        if (!nameCookie || !cookieVal) return;
        UtilsCookies.setCookie(nameCookie, cookieVal, days);
      },
      reloadPDPRecommendedByExpert(recommendedData) {
        this.$emit('reloadPDPRecommendedByExpert', recommendedData);
      },
      toggleCart() {
        if (this.shouldRedirectToBasketMobile) {
          window.location.href = this.redirectUrlBasket;
          return;
        }
        this.showModal({
          name: ICON_NAMES.MARKER,
          status: !this.isCartOpen,
        });
      },
      handlerShowSearch() {
        this.setPageScrollPosition({ x: window.scrollX, y: window.scrollY });
        this.showSearchContainer = true;
        document.body.style.overflow = 'hidden';
      },
      handlerCloseSearch() {
        this.resetIsbn();
        this.showSearchContainer = false;
        document.body.style.overflow = 'auto';
        setTimeout(() => this.restorePageScrollPosition(), 50);
      },
      resetIsbn() {
        this.$emit('resetIsbn');
      },
      restorePageScrollPosition() {
        const position = this.getPageScrollPosition;

        window.scrollTo(position.x, position.y);
      },
    },
  };
</script>
<style lang="less">
  .main-content {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .main {
    &-enter-active,
    &-leave-active {
      transition: 0.25s;
      transition-timing-function: ease-out;
      transform: translateY(0);
      opacity: 1;
    }

    &-enter-from,
    &-leave-to {
      transform: translateY(130%);
      transition-timing-function: ease-in;
      transition: 0.2s;
      opacity: 0;
    }
  }
</style>
