<template>
  <div class="wrapper__variants">
    <p
      v-for="variant in item.variant"
      :key="variant.title"
      class="variant">
      {{ variant.title }}
      {{ normalizeValue(variant.value)?.replace(variant.title, '') }}
    </p>

    <p
      v-if="item.has_variants"
      class="variant">
      {{ item.has_variants }}
    </p>

    <div
      v-if="!isBuyLater && !item.financial_info"
      :class="isCheckout ? 'btn-units-checkout' : 'btn-units'">
      <button
        v-if="!hideSelectors && !hidePlusAndMinButtons"
        :class="item.units === 1 && 'disabled'"
        @click="handleUnits(item, TYPE_ACTION.MINUS, true)">
        -
      </button>

      <div v-if="!(hideSelectors && item.units === 1)">
        <p class="variant">
          {{ item.units }}
          <span v-if="hideSelectors">{{ $t('cart.units') }}</span>
        </p>
      </div>

      <button
        v-if="!hideSelectors && !hidePlusAndMinButtons"
        :disabled="isDisabledPlusBtn"
        :class="isDisabledPlusBtn && 'disabled'"
        @click="handleUnits(item, TYPE_ACTION.PLUS, true)">
        +
      </button>
    </div>
    <div
      v-if="item.financial_info"
      class="finance_wrapper">
      <span class="finance_wrapper--text">{{ $t('product.finance.financed') }}</span>
    </div>
  </div>
</template>

<script>
  import CheckoutLogic from 'CommonMixins/checkoutLogic';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    mixins: [CheckoutLogic],
    props: {
      item: {
        type: Object,
        required: true,
      },
      isDisabledPlusBtn: {
        type: Boolean,
        default: false,
      },
      isCheckout: {
        type: Boolean,
        default: false,
      },
      isBuyLater: {
        type: Boolean,
        default: false,
      },
      hideSelectors: {
        type: Boolean,
        default: false,
      },
      hidePlusAndMinButtons: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        TYPE_ACTION: Object.freeze({
          PLUS: 'PLUS',
          MINUS: 'MINUS',
        }),
      };
    },
    computed: {
      ...mapGetters('cart', {
        isFireFlyCart: 'getIsFireFlyCart',
      }),
    },
    methods: {
      ...mapActions('cart', {
        updateCartItem: 'updateCartItem',
      }),
      handleUnits(item, action, is_from_checkout) {
        if (item.units === 1 && action === this.TYPE_ACTION.MINUS) return;
        if (this.isFireFlyCart) {
          return this.updateCartItem({ item, action, is_from_checkout });
        }
        return this.changeUnits(item, action, is_from_checkout);
      },
      normalizeValue(value) {
        if (typeof value === 'object') {
          return value[0] == value[1] ? value[0] : value.join(' ');
        }
        return value;
      },
    },
  };
</script>
