<template>
  <div>
    <div class="cart-list__item">
      <div class="basket__wrapper">
        <CartImage :item="item" />

        <div class="description__wrapper">
          <Brand
            v-if="item.brand && item.brand.name && item.type != ITEM_TYPES.ECI_PRIME"
            :brand="item.brand"
            :className="'cart'" />

          <a
            class="title"
            :href="goToPDP(item)">
            {{ item.title }}
          </a>

          <Price
            v-if="!item.must_hide_price"
            :item="item" />

          <CartVariants
            :isDisabledPlusBtn="!canAddUnits"
            :hidePlusAndMinButtons="!!(item.costs?.length || hideSelectors)"
            :hideSelectors="Boolean(item.custom_items)"
            :item="item" />
        </div>

        <CartActionsMiniBasket
          v-if="!hideActions"
          :item="item" />
      </div>
      <div
        v-if="item.description"
        class="textError">
        <p>* {{ $t(item.description) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import CartImage from './UI/CartImage.vue';
  import Price from 'CommonComponents/Price/Price.vue';
  import CartVariants from './UI/CartVariants.vue';
  import CartActionsMiniBasket from 'Components/Checkout/CartActionsMiniBasket.vue';
  import CartActionsMixin from 'CommonMixins/cartActionsLogic';

  import Brand from 'CommonComponents/Product/Brand.vue';

  import BTN_PLUS_DISABLED_TYPES from 'CommonUtils/btnPlusDisabledTypes';

  import PDPRedirect from 'CommonUtils/pdpRedirect.js';
  import ITEM_TYPES from 'CommonUtils/itemTypes.js';

  export default {
    name: 'CartItem',
    components: {
      CartImage,
      Price,
      CartVariants,
      CartActionsMiniBasket,
      Brand,
    },
    mixins: [CartActionsMixin],
    props: {
      hideSelectors: {
        type: Boolean,
        default: false,
      },
      hideActions: {
        type: Boolean,
        default: false,
      },
      item: {
        type: Object,
        required: true,
      },
      canAddUnits: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        BTN_PLUS_DISABLED_TYPES,
        ITEM_TYPES,
        goToPDP: PDPRedirect.goToPDP,
      };
    },
  };
</script>
