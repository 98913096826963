<template>
  <section class="links">
    <div class="links__wrapper">
      <div class="links__block">
        <div id="footerCompraOnline" role="button" :aria-expanded="checkedBlocks.compraOnline" :aria-control="$t('layouts.footer.compra_online')" tabindex="0" class="links__label" @click.stop="checkedBlocks.compraOnline = !checkedBlocks.compraOnline" @keydown.prevent.enter.space="checkedBlocks.compraOnline = !checkedBlocks.compraOnline"><span class="links__title">{{ $t("layouts.footer.compra_online") }}</span><i class="links__icon links__icon--arrow icon arrow-down" :class="{ 'links__icon--open': checkedBlocks.compraOnline }"></i></div>
        <AccordionAnimation>
          <ul class="links__list" aria-labelledby="footerCompraOnline" v-show="checkedBlocks.compraOnline">
            <li><a class="links__link" :href="sitebar?.links?.my_account">{{ sitebar?.labels?.my_account }}</a></li>
            <li><a class="links__link" :href="sitebar?.links?.my_orders">{{ sitebar?.labels?.my_orders }}</a></li>
            <li><a class="links__link" :href="links?.compra_online?.formas_pago">{{ $t("layouts.footer.formas_pago") }}</a></li>                        
            <li><a class="links__link" :href="links?.compra_online?.gastos_envio">{{ $t("layouts.footer.gastos_envio") }}</a></li>            
            <li><a class="links__link" :href="links?.compra_online?.devoluciones">{{ $t("layouts.footer.devoluciones") }}</a></li>
            <li><a class="links__link" :href="links?.compra_online?.ventajas_servicios">{{ $t("layouts.footer.ventajas_servicios") }}</a></li>
          </ul>
        </AccordionAnimation>  
      </div>
      <div class="links__block">
        <div id="footerSiguenos" role="button" :aria-expanded="checkedBlocks.siguenos" :aria-control="$t('layouts.footer.siguenos')" tabindex="0" class="links__label" @click.stop="checkedBlocks.siguenos = !checkedBlocks.siguenos" @keydown.prevent.enter.space="checkedBlocks.siguenos = !checkedBlocks.siguenos"><span class="links__title">{{ $t("layouts.footer.siguenos") }}</span><i class="links__icon links__icon--arrow icon arrow-down" :class="{ 'links__icon--open': checkedBlocks.siguenos }"></i></div>        
        <AccordionAnimation>
        <ul class="links__list" aria-labelledby="footerSiguenos" v-show="checkedBlocks.siguenos">
          <li><a class="links__link links__link--icon" :href="links?.siguenos?.instagram" :alt="$t('layouts.footer.links.rrss_instagram')" :title="$t('layouts.footer.links.rrss_instagram')"><i class="links__icon links__icon--rrss icon instagram-big"></i><span>{{ $t('layouts.footer.links.rrss_instagram_name') }}</span></a></li>
          <li><a class="links__link links__link--icon" :href="links?.siguenos?.tiktok" :alt="$t('layouts.footer.links.rrss_tiktok')" :title="$t('layouts.footer.links.rrss_tiktok')"><i class="links__icon links__icon--rrss icon tiktok-big"></i><span>{{ $t('layouts.footer.links.rrss_tiktok_name') }}</span></a></li>
          <li><a class="links__link links__link--icon" :href="links?.siguenos?.pinterest" :alt="$t('layouts.footer.links.rrss_pinterest')" :title="$t('layouts.footer.links.rrss_pinterest')"><i class="links__icon links__icon--rrss icon pinterest-big"></i><span>{{ $t('layouts.footer.links.rrss_pinterest_name') }}</span></a></li>
          <li><a class="links__link links__link--icon" :href="links?.siguenos?.twitter" :alt="$t('layouts.footer.links.rrss_twitter')" :title="$t('layouts.footer.links.rrss_twitter')"><i class="links__icon links__icon--rrss icon twitter-big"></i><span>{{ $t('layouts.footer.links.rrss_twitter_name') }}</span></a></li>
          <li><a class="links__link links__link--icon" :href="links?.siguenos?.facebook" :alt="$t('layouts.footer.links.rrss_facebook')" :title="$t('layouts.footer.links.rrss_facebook')"><i class="links__icon links__icon--rrss icon facebook-big"></i><span>{{ $t('layouts.footer.links.rrss_facebook_name') }}</span></a></li>
          <li><a class="links__link links__link--icon" :href="links?.siguenos?.youtube" :alt="$t('layouts.footer.links.rrss_youtube')" :title="$t('layouts.footer.links.rrss_youtube')"><i class="links__icon links__icon--rrss icon youtube-big"></i><span>{{ $t('layouts.footer.links.rrss_youtube_name') }}</span></a></li>
        </ul>
        </AccordionAnimation>      
      </div>
      <div class="links__block">
        <div id="footerTiendas" role="button" :aria-expanded="checkedBlocks.tiendas" :aria-control="$t('layouts.footer.centros_comerciales')" tabindex="0" class="links__label" @click.stop="checkedBlocks.tiendas = !checkedBlocks.tiendas" @keydown.prevent.enter.space="checkedBlocks.tiendas = !checkedBlocks.tiendas"><span class="links__title">{{ $t("layouts.footer.centros_comerciales") }}</span><i class="links__icon links__icon--arrow icon arrow-down" :class="{ 'links__icon--open': checkedBlocks.tiendas }"></i></div>        
        <AccordionAnimation>
        <ul class="links__list" aria-labelledby="footerTiendas" v-show="checkedBlocks.tiendas">
            <li><a class="links__link" :href="links?.tiendas?.busca_centro">{{ $t("layouts.footer.busca_centro") }}</a></li>
            <li><a class="links__link" :href="links?.tiendas?.horarios_dias">{{ $t("layouts.footer.horarios_dias") }}</a></li>
            <li><a class="links__link" :href="links?.tiendas?.gourmet_experience">{{ $t("layouts.footer.gourmet_experience") }}</a></li>
            <li><a class="links__link" :href="links?.tiendas?.cafeteria_restaurantes">{{ $t("layouts.footer.cafeteria_restaurantes") }}</a></li>
            <li><a class="links__link" :href="links?.tiendas?.eventos">{{ $t("layouts.footer.eventos") }}</a></li>            
            
        </ul>
        </AccordionAnimation>
      </div>
      <div class="links__block">
        <div id="footerContenidos" role="button" :aria-expanded="checkedBlocks.contenidos" :aria-control="$t('layouts.footer.contenidos')" tabindex="0" class="links__label" @click.stop="checkedBlocks.contenidos = !checkedBlocks.contenidos" @keydown.prevent.enter.space="checkedBlocks.contenidos = !checkedBlocks.contenidos"><span class="links__title">{{ $t("layouts.footer.contenidos") }}</span><i class="links__icon links__icon--arrow icon arrow-down" :class="{ 'links__icon--open': checkedBlocks.contenidos }"></i></div>
        <AccordionAnimation>
        <ul class="links__list" aria-labelledby="footerContenidos" v-show="checkedBlocks.contenidos">
          <li><a class="links__link" :href="links?.contenidos?.catalogos_digitales">{{ $t("layouts.footer.catalogos_digitales") }}</a></li>          
          <li><a class="links__link" :href="links?.contenidos?.aptc_supermercado">{{ $t("layouts.footer.aptc_supermercado") }}</a></li>          
          <li><a class="links__link" :href="links?.contenidos?.ideas_consejos">{{ $t("layouts.footer.ideas_consejos") }}</a></li>
          <li><a class="links__link" :href="links?.contenidos?.ambito_cultural">{{ $t("layouts.footer.ambito_cultural") }}</a></li>
          <li><a class="links__link" :href="links?.contenidos?.tarjeta_regalo">{{ $t("layouts.footer.tarjeta_regalo") }}</a></li>
          <li><a class="links__link" :href="links?.contenidos?.promocion_repsol">{{ $t("layouts.footer.promocion_repsol") }}</a></li>
          <li><a class="links__link" :href="links?.contenidos?.pitiflu">{{ $t("layouts.footer.pitiflu") }}</a></li>          
        </ul>
        </AccordionAnimation>
      </div>
      <div class="links__block">
        <div id="footerGrupoEci" role="button" :aria-expanded="checkedBlocks.grupoEci" :aria-control="$t('layouts.footer.grupo_corte')" tabindex="0" class="links__label" @click.stop="checkedBlocks.grupoEci = !checkedBlocks.grupoEci" @keydown.prevent.enter.space="checkedBlocks.grupoEci = !checkedBlocks.grupoEci"><span class="links__title">{{ $t("layouts.footer.grupo_corte") }}</span><i class="links__icon links__icon--arrow icon arrow-down" :class="{ 'links__icon--open': checkedBlocks.grupoEci }"></i></div>          
        <AccordionAnimation>
        <ul class="links__list" aria-labelledby="footerGrupoEci" v-show="checkedBlocks.grupoEci">            
          <li><a class="links__link" :href="links?.grupo_corte?.informacion_corporativa">{{ $t("layouts.footer.informacion_corporativa") }}</a></li>            
          <li><a class="links__link" :href="links?.grupo_corte?.trabajar_corte">{{ $t("layouts.footer.trabajar_corte") }}</a></li>
          <li><a class="links__link" :href="links?.grupo_corte?.programa_afiliados">{{ $t("layouts.footer.programa_afiliados") }}</a></li>
          <li><a class="links__link" :href="links?.grupo_corte?.sostenibilidad">{{ $t("layouts.footer.sostenibilidad") }}</a></li>
          <li><a class="links__link" :href="links?.grupo_corte?.entidades">{{ $t("layouts.footer.entidades") }}</a></li>            
        </ul>
        </AccordionAnimation>
      </div>
    </div>
  </section>
</template>

<script> export default { name: 'Links' }; </script>
<script setup>
  import { ref } from "vue"
  import { mapGetters } from "CommonUtils/store/state"
  import AccordionAnimation from 'CommonComponents/animations/AccordionAnimation.vue'

  const { sitebar, links } = mapGetters('page', {
    sitebar: 'getSiteBar',
    links: 'getFooterLinks'
  })
  
  const checkedBlocks = ref({})
</script>

