<template>
  <div :class="isCheckout ? 'actions__wrapper__checkout' : 'actions__wrapper'">
    <Popover v-if="false" borderColor="#F2F2F2" backgroundColor="#F2F2F2" align="end">
      <template v-slot:target>
        <i
          v-if="item.type !== ITEM_TYPES.ECI_PRIME"
          role="button"
          class="pointer i-save icon-btn"
          @click="redirectAdd"
        ></i>
      </template>
      <template v-slot:info>
        <div style="white-space: nowrap;">
          {{ $t('checkout.buy_later') }}
        </div>
      </template>
    </Popover>
    <Popover borderColor="#F2F2F2" backgroundColor="#F2F2F2" align="end">
      <template v-slot:target>
        <i
          role="button"
          @click="redirectDelete"
          class="pointer i-delete icon-btn"
        ></i>
      </template>
      <template v-slot:info>
        <div class="icon-tooltip">
          {{ $t('checkout.delete') }}
        </div>
      </template>
    </Popover>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import SITE_TYPES from 'CommonUtils/siteTypes.js';
import ITEM_TYPES from 'CommonUtils/itemTypes.js';

import Popover from 'CommonComponents/Popover/Popover.vue';

import CheckoutLogic from 'CommonMixins/checkoutLogic.js';

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    isCheckout: {
      type: Boolean,
      default: false
    },
    isBuyLater: {
      type: Boolean,
      default: false
    }
  },
  mixins: [CheckoutLogic],
  data() {
    return {
      sites: SITE_TYPES,
      ITEM_TYPES
    };
  },
  computed: {
    ...mapGetters('buyLater', {
      getBuyLater: 'getBuyLater'
    }),
    ...mapGetters('cart', {
      isLogged: 'isLogged'
    })
  },
  methods: {
    redirectDelete() {
      if (this.isBuyLater) {
        this.deleteItemInBuyLater(this.item);
      } else {
        this.deleteItem(this.item);
      }
    },
    redirectAdd() {
      if (this.isLogged) {
        if (this.isBuyLater) {
          this.handleAddCartProduct(this.item);
        } else {
          this.handleSaveLater(this.item);
        }
      } else {
        this.showError();
      }
    },
    ...mapActions('cart', {
      showError: 'showError'
    })
  },
  components: {
    Popover
  }
};
</script>
