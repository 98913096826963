<template>
  <div>
    <div v-if="item.discount" class="discount__wrapper">
      <div class="wrapper__price-without-discount">
        <p v-html="formatPrice(item.unit_price,this.type)" class="price-without-discount" />
      </div>

      <div class="wrapper__price">
        <p v-html="formatPrice(item.unit_sale_price,this.type)" class="price-without-broke" />
      </div>

      <span class="discount">{{ item.discount }} %</span>
    </div>

    <p v-else v-html="formatPrice(item.unit_price,this.type)" class="price" />
  </div>
</template>

<script>
import PriceFilters from 'CommonUtils/priceFilters.js';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'COMPLEX',
    },
  },
  data() {
    return {
      formatPrice: PriceFilters.formatPrice
    }
  },
};
</script>