<template>
  <div class="details__wrapper">
    <!-- <RowDetails
      :label="$t('cart.subtotal')"
      :info="subtotal" /> -->
    
    <div
      v-for="(discount, index) in fullDiscountsData"
      :key="index"
      class="row_details_discounts_wrapper">
      <RowDetails
        :label="`${discount.discount_description[0]}`"
        :info="`${discount.discount_price}`" />
    </div>
    <RowDetails
      label="Total"
      :info="total_price"
      :isTotalPrice="true" />

    <button
      class="btn-apply-cart pointer"
      @click="closeModal()">
      {{ isCSC ? $t('cart.return_csc') : $t('cart.checkout') }}
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RowDetails from './RowDetails.vue';

export default {
  props: {
    summary: {
      type: Object,
      required: true
    },
    fullDiscountsData: {
      type:Object,
      required:true
    }
  },
  data() {
    return {
      subtotal: '',
      total_shipping_price: '',
      total_price: ''
    };
  },
  watch: {
    summary: function(newSummary) {
      this.setPrices(newSummary);
    }
  },
  created() {
    this.setPrices(this.summary);
  },
  computed: {
    ...mapGetters('cart', {
      redirectUrlBasket: 'getRedirectUrlBasket',
      isCSC: 'getIsCSC'
    }),
  },
  methods: {
    ...mapActions('uiState', {
      hideAllModals: 'hideAllModals'
    }),
    closeModal() {
      document.body.style.overflowY = 'auto';
      this.hideAllModals();
      window.location.href = this.redirectUrlBasket;
    },
    setPrices(summary) {
      const { subtotal, total_shipping_price, total_price } = summary;
      this.subtotal = subtotal;
      this.total_shipping_price = total_shipping_price;
      this.total_price = total_price;
    },
  },
  components: {
    RowDetails
  },
  created() {
    this.setPrices(this.summary);
  }
};
</script>