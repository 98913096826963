<template>
  <div class="cart__container">
    <div v-if="isLoading" class="loadingCart"><Spinner /></div>
    <ContainerDesktop />
  </div>
</template>

<script>
import ContainerDesktop from './ContainerDesktop.vue';
import Spinner from 'CommonComponents/UI/Spinner.vue';

import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('cart', {
      isLoading: 'getLoadingStatus',
      redirectUrlBasket: 'getRedirectUrlBasket',
    }),
  },
  components: {
    ContainerDesktop,
    Spinner,
  },
};
</script>

<style lang="less" scoped>
.loadingCart {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .text-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    height: 100%;
  }
}
</style>